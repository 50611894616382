<template>
  <div class="list_box">
    <div class="detail">
      <div class="type">{{ item.productName }}</div>
      <ul class="left">
        <li class="item">
          <span class="item_name">保单号:</span>
          <span class="item_data">{{ item.policyNo }}</span>
        </li>
        <li class="item">
          <span class="item_name">保障期限:</span>
          <span class="item_data">{{ this.item.effectiveDate+"至"+this.item.expireDate }}</span>
        </li>
        <li class="item">
          <span class="item_name">订单状态: </span>
          <span class="item_data">{{ orderStatus }}</span>
        </li>
      </ul>
    </div>
    <div :class="['order-status', item.status]">
      <div class="order-status-item">{{ status }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PolicyItem",
  data() {
    return {
      statusList: {
        'WAIT_TO_INSURED': '待投保',
        'INSURED': '已投保',
        'AVAILABLE': '已生效',
        'CORRECTED': '已批改',
        'WITHDRAWALED': '已撤保'
      },
      orderStatusList: {
        'INIT': '未支付',
        'PAID': '已支付',
        'REFUND_FAILURE': '退款失败',
        'REFUND_SUCCESS': '退款成功'
      }
    }
  },
  computed: {
    status() {
      return this.statusList[this.item.status];
    },
    orderStatus() {
      return this.orderStatusList[this.item.payStatus];
    },
    insurancePeriod() {
      if (this.item.insurancePeriod === 'TYONE') return '一年';
      if (this.item.insurancePeriod === 'TMONE') return '一个月';
      return '';
    }
  },
  props: ['item', 'active'],
  methods: {

  }
}
</script>

<style lang="less">
.list_box {
  display: flex;
  background-color: #fff;
  border-radius: 0.08rem;
  margin-bottom: 0.2rem;
  .type {
    font-size: 0.28rem;
    color: #333;
    margin-bottom: 0.1rem;
    font-weight: 700;
  }
  .order-status {
    width: 1.2rem;
    font-size: 0.2rem;
    color: #fff;
    padding: 0.2rem 0.1rem;
    position: relative;
    .order-status-item {
      width: 100%;
      text-align: center;
      position: absolute;
      left: 0;
      height: 1rem;
      line-height: 1rem;
      top: 50%;
      margin-top: -.5rem;
    }
  }
  .WAIT_TO_INSURED {
    background-color: rgba(149, 242, 4, 1);
  }
  .INSURED {
    background-color: rgba(92, 173, 255, 1);
  }
  .AVAILABLE {
    background-color: rgba(170, 170, 170, 1);
  }
  .CORRECTED {
    background-color: #1989fa;
  }
  .WITHDRAWALED {
    background-color: #ee0a24;
  }
  .detail {
    width: 100%;
    padding: 0.2rem;
    .left {
      width: 5.8rem;
      line-height: 0.48rem;
      font-size: 0.26rem;
      color: #666;
      .item {
        display: flex;
        flex-direction: row;
        overflow:hidden;

          text-overflow:ellipsis;
        .item_name {
          width: 1.5rem;
        }
        .item_data{
          width: calc(5.8rem - 1.5rem);
          overflow:hidden;

          text-overflow:ellipsis;

          display:-webkit-box;

          -webkit-box-orient:vertical;

          -webkit-line-clamp:2;
        }
      }
    }
  }
}
</style>