<template>
  <div class="my_order_wrapper">
    <!-- <van-tabs
      sticky
      v-model="active"
      color="#eb5b53"
      line-height="0.06rem"
      title-active-color="#eb5b53"
      title-inactive-color="#666"
      offset-top="1rem"
    >
      <van-tab title="保单" title-style="font-size:0.3rem"></van-tab>
      <van-tab title="续保" title-style="font-size:0.3rem"></van-tab>
    </van-tabs> -->
    <div class="list">
      <div
        v-for="(item, index) in itemMsg"
        :key="index"
        @click="goPolicyDetail(item)"
      >
        <policy-item :item="item" :active="active"></policy-item>
      </div>
    </div>
  </div>
</template>

<script>
// import Vue from 'vue';
import { Tab, Tabs } from 'vant';
import './PolicyList.less'
import PolicyItem from './../../components/PolicyItem';
import { getMyOrders } from "../../api/api";
import { baseConfig } from '../../utils/request'
export default {
  name: 'PolicyList',
  data() {
    return {
      active: 0,
      itemMsg: [
      ]
    }
  },
  components: {
    'van-tab': Tab,
    'van-tabs': Tabs,
    'policy-item': PolicyItem
  },
  async mounted() {
	  let loginInfo = localStorage.getItem('loginInfo');
	  if (!this.$route.query.sourceType && (!loginInfo || !JSON.parse(loginInfo).openId || !JSON.parse(loginInfo).unitId)) {
	    const url = `${baseConfig.locationHref}`;
	    const oldPath = encodeURIComponent(window.location.href.split(location.origin)[1] + '&login');
	    window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${baseConfig.appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=${oldPath}`
	  }else{
		  const PDdata = await getMyOrders({
		    openId: JSON.parse(localStorage.getItem('loginInfo')).openId,
		    unitId: JSON.parse(localStorage.getItem('loginInfo')).unitId
		  });
		  console.log(PDdata);
		  
		  this.itemMsg = PDdata.data;
	  }
  },
  methods: {
    goPolicyDetail(item) {
      console.log(item);
      this.$router.push({
        path: './policyDetails',
        query: {
          policyId: item.id
        }
      })
    }
  }
}
</script>

<style>
</style>